import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styled, { css } from 'styled-components'
import Theme from './shared/colors'
import Spacing from './shared/spacing'

import { H2, P, UL, LI, typestyle } from './shared/typography'

const GlobalFooter = () => {
    const data = useStaticQuery(graphql`
        {
            contentfulHomePage(slug: { eq: "atd-home" }) {
                id
                companyLogo {
                    ...Asset
                }
                companyLegalName
                addressStreet
                addressCity
                addressState
                addressPostalCode
            }
        }
    `)

    const siteSettings = data.contentfulHomePage

    return (
        <Footer>
            <Copyright size="caption">
                {siteSettings.companyLegalName} &copy; 2020 |{' '}
                {siteSettings.addressStreet}, {siteSettings.addressCity},{' '}
                {siteSettings.addressState} {siteSettings.addressPostalCode}
            </Copyright>
        </Footer>
    )
}

const Footer = styled.footer`
    padding: ${Spacing.md} 4vw;
    width: 100vw;
    background-color: ${Theme.brand.primary};
    text-align: center;
    color: ${Theme.text.inverse};
`

const Copyright = styled(P)``

export default GlobalFooter
