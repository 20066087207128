import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'

import Footer from '../components/global-footer'
import Header from '../components/global-header'
import PrimaryContent from '../components/primary-content'

class HomePageTemplate extends React.Component {
    render() {
        const page = get(this.props, 'data.contentfulHomePage')
        const hasLeadImage = typeof page.leadImage !== 'undefined' && page.leadImage !== null

        return (
            <Layout location={this.props.location}>
                <Header />
                <main>
                    <PrimaryContent data={page.primaryContent} />
                </main>
                <Footer />
            </Layout>
        )
    }
}

export default HomePageTemplate

export const pageQuery = graphql`
    query HomePageBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        contentfulHomePage(slug: { eq: $slug }) {
            title
            slug
            primaryContent {
                raw
                references {
                    ...BlockImageWithContent
                    ...BlockExternalEmbed
                }
            }
        }
    }
`
