import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import Theme from './shared/colors'

import ImageWithSVGSupport from './image-with-svg-support'
import Spacing from './shared/spacing'

const GlobalHeader = ({ title }) => {
    const data = useStaticQuery(graphql`
        {
            contentfulHomePage(slug: { eq: "atd-home" }) {
                id
                companyLogo {
                    ...Asset
                }
            }
        }
    `)

    const siteSettings = data.contentfulHomePage

    return (
        <Header>
            {siteSettings.companyLogo && (
                <Link href="https://www.automationtd.com/">
                    <Logo
                        title={siteSettings.companyLogo.title}
                        fluid={siteSettings.companyLogo.fluid}
                        file={siteSettings.companyLogo.file}
                    />
                </Link>
            )}
        </Header>
    )
}

const Header = styled.header`
    display: grid;
    padding: ${Spacing.xxl} 4vw;
    width: 100vw;
    text-align: center;
    color: ${Theme.text.brand};
`

const Link = styled.a`
    display: inline-block;
    text-decoration: none;
`

const Logo = styled(ImageWithSVGSupport)`
    display: block;
    margin: 0 auto;
    max-width: 240px;
`

export default GlobalHeader
